<template>
  <div>
    <!-- text fields -->
    <v-card class="mx-auto pb-5 pt-4 rounded-xl" max-width="600" style="z-index: 1000">
      <v-card-title class="mx-auto justify-center flex-column">
        <a href="/" class="logo-wrapper-alt">
          <img height="36" src="/img/DNA_Symbol.png" />
          <v-img contain height="36" src="/img/logo.svg"></v-img>
        </a>
        <br />
        <strong class="text--secondary">Log in to Your Account</strong>
        <!-- <i class="fal fa-sign-in mr-2" style="line-height: 0"></i>Log in to your account -->
      </v-card-title>
      <v-form v-model="valid" @submit.prevent="submit" class="pa-3 mt-0 pt-0">
        <div color="white" style="position: sticky; top: 0; z-index: 1">
          <v-tabs color="error" v-model="tab" centered>
            <v-tab :key="0" :disabled="loading">Office 365 Login</v-tab>
            <v-tab :key="1" :disabled="loading">Email Login</v-tab>
          </v-tabs>
          <v-divider></v-divider>
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="0">
            <p class="text-center pa-4 mt-8 d-flex flex-column align-center">
              <!-- <img width="250" src="/img/art/undraw_designer_re_5v95.svg" /> -->
              <img width="300" src="/img/art/windows_login-cropped.svg" />
              <v-btn color="info" @click="azureSignIn" :loading="loading" class="mt-5">
                <i class="fab fa-microsoft mr-2" style="line-height: 0"></i>
                Login With your Microsoft Account
              </v-btn>
              <!-- <v-btn color="info" @click="azureSignInRedirect" :loading="loading" class="mt-5">
                <i class="fab fa-microsoft mr-2" style="line-height: 0"></i>
                Login With your Microsoft Account
                <span class="opacity-54 fs-12px ml-2">w/ Redirect</span>
              </v-btn> -->
            </p>
          </v-tab-item>
          <v-tab-item :key="1">
            <v-container>
              <v-row align="center" justify="center" style="height: 200px">
                <v-col cols="12" md="8">
                  <v-text-field-alt
                    v-model="userName"
                    :rules="[allRules.required, allRules.validEmail, allRules.noWhiteSpaces]"
                    required
                    label="Email"
                    placeholder="person@example.com"
                    id="emailInput"
                  ></v-text-field-alt>
                  <v-text-field-alt
                    v-model="password"
                    :append-icon="passwordIsVisible ? 'fal fa-eye' : 'fal fa-eye-slash'"
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    :type="passwordIsVisible ? 'text' : 'password'"
                    id="passwordInput"
                    label="Password"
                    placeholder="Password"
                    required
                    @click:append="passwordIsVisible = !passwordIsVisible"
                  ></v-text-field-alt>
                </v-col>
              </v-row>
              <v-row justify="center" aling="center">
                <v-col cols="12" md="8" class="d-flex justify-center">
                  <v-btn
                    type="submit"
                    color="info"
                    :disabled="!valid || loading"
                    :loading="loading"
                  >
                    <i class="fal fa-sign-in mr-2" style="line-height: 0"></i>
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <!-- <img class="img-fluid img-bg" src="/img/art/urban-105.png" /> -->
    </v-card>
    <div class="img-bg"></div>
    <div class="bg-confetti">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 320">
        <path
          fill="rgba(71, 68, 67, 0.03)"
          fill-opacity="1"
          d="M0,192L48,160C96,128,192,64,288,64C384,64,480,128,576,170.7C672,213,768,235,864,229.3C960,224,1056,192,1152,197.3C1248,203,1344,245,1392,266.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import MSAuthService from "../services/ms-auth-service";
import ApiService from "../../Admin/services/StaffService.js";

export default {
  name: "Login",
  data: () => ({
    valid: false,
    passwordIsVisible: false,
    permsUpdatedNotice: false,
    loading: false,
    password: "",
    userName: "",
    tab: 0,
  }),
  created() {
    if (this.$route.query.r && this.$route.query.r == "permissions-updated") {
      this.permsUpdatedNotice = true;
    }
  },
  mounted() {
    if (this.permsUpdatedNotice) {
      this.$dialog.notify.warning(
        "Your permissions have been updated!<br/>Please login to your account again!",
        {
          position: "top-right",
          icon: "fas fa-user-edit fs-16px",
          timeout: 0,
        }
      );
    }
  },
  methods: {
    //https://concordia-app-dev.azurewebsites.net/#code=0.ARwAxz-V0qqch0WvIJszbYcNEk9X6kCBxGJAlQ--f_x96R8cAGY.AgABAAIAAAD--DLA3VO7QrddgJg7WevrAgDs_wUA9P_yyt6N4iK0N-NEcscNT2OK74C5YWW7QRHvrKasohjK_k0m5Tj4wPvdqtZJxKozvDc8-IY0aCDo5Uckjz94amnuqWzHPyEN4SclqWVJhNlwPOfjS8j9SA1_bGnBYcwYJd6SPu6krDrqWmPMQckpERT0w2PhK0feISy6lcnslMkM0Z941nqp3a1QmKZaKRGc7ukMbJMsPDWXEV6-KIHt5n9tzAYmcP1t9dC4BQOEDqs_rw1dsThtqLcYHRZETXU8x4qwkGxygVScJvN5VUzxUTddSQDM-CdmbjvPWK-vxD0gdVhZOz6VSsRyi50x3cqbwcanCKOPWnDuT9YqKt0zuO5FGYXgjL2AsJXiN9YHf4x7wfL6f7SU7KO_O4fcjl0T6a5cU9ozeLytM5ST0kOR2q-YLpytasWMjhcylDzSKqbYgdoCNFuHIZSV8GPxDLoVEskxPRvOWcal3aN04gGmmgwUBMMgsFr3D4uYDyvWjkqLIhrS-fds3v4vaYEOeBzWdogirCt3oAuKDhvideoTwmRh7bvnK3mngAt4AmyFd429Ob24bLRbJ05tWiZT1q0iLkWiMfwyfPi6LUR48XyMXnHqHprreXR1RynWlHqe_rb42EX62TkkKC5e7ZkeRRn8mJmZKRgYUlZ8pBybPo-7XbchZA0hZoHqJeuglIUTCMX695W30zNQw45KjvPhmXGu6iMrhp9QNl0pd9qqk8TZipCxH9TUcxfEcanob3vjSpR0PVAL-bJ1mJOUYk7--oH4PWV_i_6ImClgIbeVcLl1dREamVYcul-R6O9lZTuj0cpshKcCbm7QzJHluUQrkG0syMtHBeDmPfhFOjlPV_naxznlO6lUMynEiQ&client_info=eyJ1aWQiOiIzNGE1MGJiYS1kY2VmLTQ0ZjYtOTljMC1kOWNjYzRhYjliYTMiLCJ1dGlkIjoiZDI5NTNmYzctOWNhYS00NTg3LWFmMjAtOWIzMzZkODcwZDEyIn0&state=eyJpZCI6ImZiNmJjM2U0LTFkNmQtNGZhYS05OTEwLWMwN2ViYzljMWE4MyIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3d&session_state=bba266e8-c490-42bc-9137-183723ad6af1
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    clear() {
      this.userName = "";
      this.password = "";
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("login", { userName: this.userName, password: this.password })
        .then((resp) => {
          this.loading = false;
          window.location.href = "/dashboard";
          // setTimeout(() => {
          //   this.$router.push({ name: "dashboard" });
          // }, 50);
        })
        .catch((resp) => {
          this.$log(resp);
          this.loading = false;
          this.$dialog.notify.error("Login error", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    azureSignIn() {
      debugger;
      this.loading = true;
      MSAuthService.loginPopup()
        .then((response) => {
          debugger;
          this.$log("Response", response);
          if (response == null) return;
          ApiService.msLogin(response)
            .then((resp) => {
              this.$store.dispatch("setUser", resp);
              this.loading = false;
              window.location.href = "/dashboard";
              // setTimeout(() => {
              //   this.$router.push({ name: "dashboard" });
              // }, 50);
            })
            .catch((error) => {
              this.loading = false;
              this.$dialog.notify.error("Login error", {
                position: "top-right",
                timeout: 3000,
              });
            })
            .finally(() => {});
        })
        .catch((error) => {
          this.loading = false;
          this.$dialog.notify.error("Login error", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    azureSignInRedirect() {
      this.loading = true;
      MSAuthService.getMsalObj()
        .handleRedirectPromise()
        .then((response) => {
          this.$log("azureSignInRedirect", response);
          if (response !== null) {
            accountId = response.account.homeAccountId;
            // Display signed-in user content, call API, etc.
          } else {
            // In case multiple accounts exist, you can select
            const currentAccounts = MSAuthService.getMsalObj().getAllAccounts();
            this.$log("currentAccounts", currentAccounts);

            if (currentAccounts.length === 0) {
              // no accounts signed-in, attempt to sign a user in
              this.$log("no accounts signed-in, attempt to sign a user in");
              // MSAuthService.getMsalObj().loginRedirect(MSAuthService.loginRequest);
            } else if (currentAccounts.length > 1) {
              // Add choose account code here
            } else if (currentAccounts.length === 1) {
              accountId = currentAccounts[0].homeAccountId;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.logo-wrapper-alt.toolbar-logo {
  display: none !important;
}

.v-main__wrap {
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-bg {
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 0.04;
  z-index: 1;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  background-image: url("/img/art/urban-105.png");
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: contain;
  height: 100%;
  animation: animatedBackgroundHorizontal 300s linear infinite;
  filter: invert(1);
}
@keyframes animatedBackgroundHorizontal {
  from {
    background-position: left bottom;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: 4940px bottom;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: 10000px 10000px;
  }
}
.bg-confetti {
  display: block;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  // background: url("/img/bg icons confetti.png?v1");
  background-repeat: repeat;
  background-position: 0 0;
  background-size: auto 100%; /*adjust s value for speed*/
  animation: animatedBackground 500s linear infinite;
  opacity: 0.87;
}

.bg-confetti svg {
  display: block;
  position: fixed;
  z-index: -1;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
