export default {
  msalConfig: {
    auth: {
      clientId: "40ea574f-c481-4062-950f-be7ffc7de91f", // This is the ONLY mandatory field that you need to supply.
      authority: "https://login.microsoftonline.com/d2953fc7-9caa-4587-af20-9b336d870d12", // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: process.env.VUE_APP_MS_REDIRECT_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
      postLogoutRedirectUri: process.env.VUE_APP_MS_REDIRECT_SIGNOUT, // Simply remove this line if you would like navigate to index page after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  },

  loginRequest: {
    scopes: ["User.Read.All", "Group.Read.All"],
  },
  msalObj: null,
  getMsalObj() {
    var address = document.location.origin;
    this.msalConfig.auth.redirectUri = address;
    this.msalConfig.auth.postLogoutRedirectUri = address + "/logout";
    var env = process.env.VUE_APP_API_ENDPOINT;

    if (!this.msalObj) {
      this.msalObj = new msal.PublicClientApplication(this.msalConfig);
    }
    return this.msalObj;
  },
  loginPopup() {
    return this.getMsalObj().loginPopup(this.loginRequest);
  },
  loginRedirect() {
    return this.getMsalObj().loginRedirect(this.loginRequest);
  }
};
